import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6a03c0ae"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tm-loader" }
const _hoisted_2 = { class: "headline-18 w100pr mb-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_spinner = _resolveComponent("tm-spinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1),
    _createVNode(_component_tm_spinner, {
      color: "primary",
      size: "72px"
    })
  ]))
}