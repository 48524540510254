
import { defineComponent, ref } from 'vue'
import TmLoader from '@/components/shared/TmLoader.vue'

export default defineComponent({
  components: {
    TmLoader,
  },
  props: {
    codes: {
      type: Array,
    },
    loading: {
      type: Boolean,
    },
  },
  setup() {
    const isClicked = ref(false)

    return {
      isClicked,
    }
  },
})
