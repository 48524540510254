
import { defineComponent } from 'vue'
import TmSpinner from '@/components/shared/TmSpinner.vue'

export default defineComponent({
  components: {
    TmSpinner,
  },
  props: {
    title: {
      type: String,
    },
  },
})
