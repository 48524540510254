import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0704c1fc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "codes-in" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_loader = _resolveComponent("tm-loader")!

  return (_openBlock(), _createElementBlock("div", {
    class: "codes",
    onMouseleave: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isClicked = false))
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.codes, (code, i) => {
        return (_openBlock(), _createElementBlock("div", {
          key: i,
          class: _normalizeClass(["code", {
          'code--used': code.used
        }])
        }, [
          _createElementVNode("span", null, _toDisplayString(code.code), 1)
        ], 2))
      }), 128))
    ]),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_tm_loader, {
          key: 0,
          title: "Generating new codes..."
        }))
      : _createCommentVNode("", true)
  ], 32))
}