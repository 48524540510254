export const codes = [
  { code: 'DNHHE-CMLAS' }, { code: 'KHC5N-D6BZB' },
  { code: 'JEMLJ-Z2GMC', used: true }, { code: 'WE3ON-MESVV' },
  { code: 'NYHBP-52VAL' }, { code: 'B3JGN-HJLE6' },
  { code: 'OEZHI-MHX27' }, { code: 'E6OIV-2GFB7' },
  { code: 'L5SV7-T5P6H', used: true }, { code: 'DNHHE-CMLAS' },
]
export const codesNoUsed = codes.map((item: any) => ({
  ...item,
  used: false,
}))
