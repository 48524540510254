
import { defineComponent, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import Container2fa from '@/components/views/auth/2fa/Container2fa.vue'
import BackupCodes from '@/components/shared/templates/BackupCodes.vue'
import { codes } from '@/definitions/_general/_data/backupCodes'

export default defineComponent({
  components: {
    BackupCodes,
    TmButton,
    TmTooltip,
    Container2fa,
  },
  setup() {
    const copiedStatus = ref(false)
    const copyCodes = () => {
      copiedStatus.value = true
    }

    return {
      codes,
      copiedStatus,
      copyCodes,
    }
  },
})
